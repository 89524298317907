<template>
  <section id="risk-status">
    <pibot-section-heading><slot>Risk Status</slot></pibot-section-heading>

    <v-layout align-start justify-space-between wrap>
      <!-- Likelihood Card -->
      <v-flex xs12 md4>
        <pibot-data-card
          :icon="'table_chart'"
          :title="'Likelihood'"
          :subTitle="'Degradation Mechanism Probabilities'"
          :class="cardClass"
        >
          <v-list subheader dense>
            <span class="font-weight-medium">Recognised threats:</span>

            <template v-for="(val, threat) in risk_status.threats">
              <div :key="threat">
                <v-list-item>
                  <v-list-item-content>
                    <!-- <v-list-item-title v-text="threat" class="font-weight-regular" /> -->
                    <span v-text="threat" class="font-weight-regular text-body-2"/>
                  </v-list-item-content>

                  <pibot-risk-chip :risk="val" small class="ml-2" />
                </v-list-item>
                <v-divider></v-divider>
              </div>
            </template>
          </v-list>
        </pibot-data-card>
      </v-flex>

      <!-- Consequences Card -->
      <v-flex xs12 md4>
        <pibot-data-card
          :icon="'healing'"
          :title="'Consequences'"
          :subTitle="'Safety, Operational, Fiscal Consequences'"
          :class="cardClass"
        >
          <v-list subheader dense>
            <span class="font-weight-medium">Consequence of failure:</span>

            <template v-for="(subCons, category) in risk_status.consequences">
              <div :key="category">
                <v-subheader class="text-capitalize">{{category}}</v-subheader>

                <template v-for="c in subCons">
                  <div :key="c.title">
                    <v-list-item>
                      <v-list-item-content>
                        <!-- <v-list-item-title v-text="c.title" class="font-weight-regular" /> -->
                        <span v-text="c.title" class="font-weight-regular text-body-2"/>
                      </v-list-item-content>

                      <pibot-risk-chip :risk="c.value" small class="ml-2" />
                    </v-list-item>
                    <v-divider></v-divider>
                  </div>
                </template>
              </div>
            </template>
          </v-list>
        </pibot-data-card>
      </v-flex>

      <!-- Risk Card -->
      <v-flex xs12 md4>
        <pibot-data-card
          :icon="'warning'"
          :title="'Risk'"
          :subTitle="'Combination of Likelihood and Consequence'"
          :class="cardClass"
        >
          <p>
            Risk is calculated from the <a href="about:blank" target="_blank">risk matrix</a> based on the overall consequence severity and likelihood of failure threatening corrosion.
          </p>

          <v-list subheader dense>
            <span class="font-weight-medium">Risk:</span>

            <template v-for="(val, risk) in risk_status.risks">
              <div :key="risk">
                <v-list-item>
                  <v-list-item-content>
                    <!-- <v-list-item-title v-text="risk" class="font-weight-regular" /> -->
                    <span v-text="risk" class="font-weight-regular text-body-2"/>
                  </v-list-item-content>

                  <pibot-risk-chip :risk="val" small class="ml-2" />
                </v-list-item>
                <v-divider></v-divider>
              </div>
            </template>
          </v-list>
        </pibot-data-card>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
import SectionHeading from '@/components/typography/headings/SectionHeading'
import DataCard from '@/components/cards/DataCard'
import RiskChip from '@/components/chips/RiskChip'

export default {
  name: 'pibot-asset-risk-status',
  components: {
    'pibot-section-heading': SectionHeading,
    'pibot-data-card': DataCard,
    'pibot-risk-chip': RiskChip
  },
  data () {
    return {
      risk_status: {
        threats: {
          'CO2 Corrosion': 'High',
          'O2 (General) Corrosion': 'Medium'
        },
        consequences: {
          safety: [
            {
              title: 'Hazard to health/safety',
              value: 'High'
            }
          ],
          operational: [
            {
              title: 'Downstream dependency',
              value: 'Medium'
            },
            {
              title: 'Difficulty unplanned maintenance',
              value: 'Medium'
            }
          ],
          fiscal: [
            {
              title: 'Revenue loss due to unexpected interruption',
              value: 'Medium'
            }
          ]
        },
        risks: {
          'Overall risk status': 'Low'
        }
      }
    }
  },
  computed: {
    cardClass () {
      return ['mx-3', 'my-4']
    }
  }
}
</script>
